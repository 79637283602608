var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ProvisionChangedGutterItem from './ProvisionChangedGutterItem.vue';
import ProvisionDiffInline from './ProvisionDiffInline.vue';
import { createAndMountApp } from '../../utils/vue-utils';
import { vueI18n } from '../../i18n';
import analytics from '../analytics';
var DocDiffsManager = /** @class */ (function () {
    function DocDiffsManager(frbrExpressionUri, gutter, serviceBaseUrl) {
        this.frbrExpressionUri = frbrExpressionUri;
        this.gutter = gutter;
        this.serviceUrl = this.getServiceUrl(serviceBaseUrl);
        this.loadProvisions();
    }
    DocDiffsManager.prototype.getServiceUrl = function (baseUrl) {
        var hostname = window.location.hostname;
        // if localhost use laws.africa otherwise default to domain name of site
        var partner = hostname === 'localhost' || hostname === '127.0.0.1' ? 'laws.africa' : window.location.hostname;
        return "".concat(baseUrl, "/v1/p/").concat(partner);
    };
    DocDiffsManager.prototype.loadProvisions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, e_1, _a, provisions;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = "".concat(this.serviceUrl, "/e/changed-provisions").concat(this.frbrExpressionUri);
                        response = null;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetch(url)];
                    case 2:
                        response = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (!(response && response.ok)) return [3 /*break*/, 6];
                        return [4 /*yield*/, response.json()];
                    case 5:
                        _a = (_b.sent()).provisions, provisions = _a === void 0 ? [] : _a;
                        this.decorateChangedProvisions(provisions);
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DocDiffsManager.prototype.decorateChangedProvisions = function (provisions) {
        var _this = this;
        provisions.forEach(function (provision) {
            var item = createAndMountApp({
                component: ProvisionChangedGutterItem,
                props: {
                    provision: provision
                },
                use: [vueI18n],
                mountTarget: document.createElement('div')
            });
            _this.gutter.appendChild(item.$el);
            item.$el.addEventListener('show-changes', function (e) { return _this.showProvisionChangesInline(e.detail.provision); });
        });
    };
    DocDiffsManager.prototype.closeInlineDiff = function () {
        if (!this.inlineDiff)
            return;
        this.inlineDiff.close();
    };
    DocDiffsManager.prototype.showProvisionChangesInline = function (provision) {
        var _this = this;
        // Prevents reinitializing the same inlineDiff if it is the same provision
        if (this.inlineDiff && this.inlineDiff.provision.id === provision.id) {
            return;
        }
        if (this.inlineDiff)
            this.inlineDiff.close();
        this.inlineDiff = createAndMountApp({
            component: ProvisionDiffInline,
            props: {
                documentId: provision.id,
                provision: provision,
                frbrExpressionUri: this.frbrExpressionUri,
                serviceUrl: this.serviceUrl
            },
            use: [vueI18n],
            mountTarget: document.createElement('div')
        });
        this.inlineDiff.$el.addEventListener('close', function () {
            _this.inlineDiff = null;
        });
        analytics.trackEvent('Document', 'What changed');
    };
    return DocDiffsManager;
}());
export default DocDiffsManager;
